<template>
    <v-container class="my-5">
        <v-row>
            <v-col cols="12" sm="12">
                <v-card>
                    <v-card-title class="title primary--text font-weight-black">
                        Типы документов
                    </v-card-title>
                    <v-card-actions>
                        <v-btn @click="create" text
                               color="primary">
                            <span>Создать</span>
                            <v-icon right>mdi-plus-box</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-card v-for="v in list" :key="v.id">
            <v-layout row wrap class="pa-3">
                <v-flex xs3 class="ml-3">
                    <div class="caption light-green--text text--darken-3">Название</div>
                    <div>{{ v.name }}</div>
                </v-flex>
                <v-flex xs3>
                    <div class="d-flex justify-center body-2" v-for="f in v.fields" :key="f.key">
                        {{ f.label }}
                    </div>
                </v-flex>
                <v-flex xs1>
                    <v-btn>
                        <v-icon small
                                color="primary"
                                class="mr-2"
                                @click="edit(v.id)">
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                </v-flex>
                <v-flex xs1>
                    <v-btn>
                        <v-icon small
                                color="primary"
                                @click="del(v)">
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
            <v-divider/>
        </v-card>
    </v-container>
</template>

<script>

    import {uuid} from "uuidv4";

    export default {
        data: () => ({}),

        created() {
            this.$store.dispatch("documentTypes/fetch");
        },

        computed: {
            list() {
                return this.$store.getters["documentTypes/list"]
            }
        },

        methods: {
            create() {
                let id = uuid.Nil;
                this.$router.push({name: 'documentTypeEdit', params: {id}})
            },
            edit(id) {
                this.$router.push({name: 'documentTypeEdit', params: {id}})
            },
            del(item) {
                this.$confirm(
                    "Вы точно хотите удалить тип транспорта",
                    "Внимание",
                    "question"
                ).then(() => {
                    this.$store.dispatch("documentTypes/remove", item);
                });
            }
        }
    };
</script>